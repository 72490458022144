<template>
  <div class="body">
    <div
      style="padding-bottom: 15px;height: 95%"
      element-loading-background="rgba(0, 0, 0, 0.7)">
      <el-row>
        <el-col :span="24">
          <div>
            <eocd-monitor ref="eocdMonitor"></eocd-monitor>
          </div>
        </el-col>
      </el-row>

      <el-row v-if="paramSet" style="padding-top: 10px">
        <el-col :span="24">
          <el-button type="primary" style="width: 100%;height: 40px" @click="drawer = true">
            {{$t("vibrationData.eocdParamsSet")}}
          </el-button>
        </el-col>
      </el-row>
      <el-drawer
        size="100%"
        :before-close="handleParamsSetClose"
        title=""
        :visible.sync="drawer"
        :with-header="true">
        <div style="padding-top: 10px">
          <div style="padding-top: 4px;padding-bottom: 4px;padding-left: 6px">
            {{$t("vibrationData.parTotalSwitch")}}
          </div>
          <el-row :gutter="22">
            <el-col :span="3">&nbsp;</el-col>
            <el-col :span="9">
              <el-switch
                v-model="setTotalSwitch"
                active-text="开"
                inactive-text="关">
              </el-switch>
            </el-col>
            <el-col :span="5">
              <el-button type="primary" style="width: 100%" @click="handleReadParam(0)">
                {{$t("vibrationData.read")}}
              </el-button>
            </el-col>
            <el-col :span="5">
              <el-button type="primary" style="width: 100%" @click="handleSetParam(0)">
                {{$t("vibrationData.set")}}
              </el-button>
            </el-col>
          </el-row>
        </div>
        <div style="padding-top: 10px">
          <div style="padding-top: 4px;padding-bottom: 4px;padding-left: 6px">
            {{$t("vibrationData.parCollectSwitch")}}
          </div>
          <el-row :gutter="22">
            <el-col :span="3">&nbsp;</el-col>
            <el-col :span="9">
              <el-switch
                v-model="setCollectSwitch"
                active-text="开"
                inactive-text="关">
              </el-switch>
            </el-col>
            <el-col :span="5">
              <el-button type="primary" style="width: 100%" @click="handleReadParam(1)">
                {{$t("vibrationData.read")}}
              </el-button>
            </el-col>
            <el-col :span="5">
              <el-button type="primary" style="width: 100%" @click="handleSetParam(1)">
                {{$t("vibrationData.set")}}
              </el-button>
            </el-col>
          </el-row>
        </div>
        <div style="padding-top: 10px">
          <div style="padding-top: 4px;padding-bottom: 4px;padding-left: 6px">
            {{$t("vibrationData.parCollectCycle")}}
          </div>
          <el-row :gutter="22">
            <el-col :span="3">&nbsp;</el-col>
            <el-col :span="9">
              <el-input-number
                v-model="setCycleValue"
                style="width: 100px;"
                controls-position="right"
                :min="0"
                :max="255"></el-input-number>
              hour
            </el-col>
            <el-col :span="5">
              <el-button type="primary" style="width: 100%" @click="handleReadParam(2)">
                {{$t("vibrationData.read")}}
              </el-button>
            </el-col>
            <el-col :span="5">
              <el-button type="primary" style="width: 100%" @click="handleSetParam(2)">
                {{$t("vibrationData.set")}}
              </el-button>
            </el-col>
          </el-row>
        </div>
        <div style="padding-top: 10px">
          <div style="padding-top: 4px;padding-bottom: 4px;padding-left: 6px">
            {{$t("vibrationData.parCollectFrequency")}}
          </div>
          <el-row :gutter="22">
            <el-col :span="3">&nbsp;</el-col>
            <el-col :span="9">
              <el-select v-model="setFrequencyValue" style="width: 100px;">
                <el-option
                  v-for="item in frequencyList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="5">
              <el-button type="primary" style="width: 100%" @click="handleReadParam(3)">
                {{$t("vibrationData.read")}}
              </el-button>
            </el-col>
            <el-col :span="5">
              <el-button type="primary" style="width: 100%" @click="handleSetParam(3)">
                {{$t("vibrationData.set")}}
              </el-button>
            </el-col>
          </el-row>
        </div>
        <div style="padding-top: 10px">
          <div style="padding-top: 4px;padding-bottom: 4px;padding-left: 6px">{{$t("vibrationData.autoUpload")}}</div>
          <el-row :gutter="22">
            <el-col :span="3">&nbsp;</el-col>
            <el-col :span="9">
              <el-switch
                v-model="setAutoUploadSwitch"
                active-text="开"
                inactive-text="关">
              </el-switch>
            </el-col>
            <el-col :span="5">
              <el-button type="primary" style="width: 100%" @click="handleReadParam(4)">
                {{$t("vibrationData.read")}}
              </el-button>
            </el-col>
            <el-col :span="5">
              <el-button type="primary" style="width: 100%" @click="handleSetParam(4)">
                {{$t("vibrationData.set")}}
              </el-button>
            </el-col>
          </el-row>
        </div>
        <div v-if="false" style="padding-top: 10px">
          <div style="padding-top: 4px;padding-bottom: 4px;padding-left: 6px">
            {{$t("vibrationData.doorCollectSwitch")}}
          </div>
          <el-row :gutter="22">
            <el-col :span="3">&nbsp;</el-col>
            <el-col :span="9">
              <el-switch
                v-model="setDoorCollectSwitch"
                active-text="开"
                inactive-text="关">
              </el-switch>
            </el-col>
            <el-col :span="5">
              <el-button type="primary" style="width: 100%" @click="handleReadParam(5)">
                {{$t("vibrationData.read")}}
              </el-button>
            </el-col>
            <el-col :span="5">
              <el-button type="primary" style="width: 100%" @click="handleSetParam(5)">
                {{$t("vibrationData.set")}}
              </el-button>
            </el-col>
          </el-row>
        </div>
        <div v-if="false" style="padding-top: 10px">
          <div style="padding-top: 4px;padding-bottom: 4px;padding-left: 6px">
            {{$t("vibrationData.doorCollectCycle")}}
          </div>
          <el-row :gutter="22">
            <el-col :span="3">&nbsp;</el-col>
            <el-col :span="9">
              <el-input-number
                v-model="setDoorCycleValue"
                style="width: 100px;"
                controls-position="right"
                :min="0"
                :max="255"></el-input-number>
              day
            </el-col>
            <el-col :span="5">
              <el-button type="primary" style="width: 100%" @click="handleReadParam(6)">
                {{$t("vibrationData.read")}}
              </el-button>
            </el-col>
            <el-col :span="5">
              <el-button type="primary" style="width: 100%" @click="handleSetParam(6)">
                {{$t("vibrationData.set")}}
              </el-button>
            </el-col>
          </el-row>
        </div>
        <div style="padding-top: 10px">
          <div style="padding-top: 4px;padding-bottom: 4px;padding-left: 6px">
            {{$t("vibrationData.alarmFileSwitch")}}
          </div>
          <el-row :gutter="22">
            <el-col :span="3">&nbsp;</el-col>
            <el-col :span="9">
              <el-switch
                v-model="setAlarmFileSwitch"
                active-text="开"
                inactive-text="关">
              </el-switch>
            </el-col>
            <el-col :span="5">
              <el-button type="primary" style="width: 100%" @click="handleReadParam(7)">
                {{$t("vibrationData.read")}}
              </el-button>
            </el-col>
            <el-col :span="5">
              <el-button type="primary" style="width: 100%" @click="handleSetParam(7)">
                {{$t("vibrationData.set")}}
              </el-button>
            </el-col>
          </el-row>
        </div>
        <div style="padding-top: 10px">
          <div style="padding-top: 4px;padding-bottom: 4px;padding-left: 6px">
            {{$t("vibrationData.safetyHazardSwitch")}}
          </div>
          <el-row :gutter="22">
            <el-col :span="3">&nbsp;</el-col>
            <el-col :span="9">
              <el-switch
                v-model="safetyHazardSwitch"
                active-text="开"
                inactive-text="关">
              </el-switch>
            </el-col>
            <el-col :span="5">
              <el-button type="primary" style="width: 100%" @click="handleReadParam(50)">
                {{$t("vibrationData.read")}}
              </el-button>
            </el-col>
            <el-col :span="5">
              <el-button type="primary" style="width: 100%" @click="handleSetParam(50)">
                {{$t("vibrationData.set")}}
              </el-button>
            </el-col>
          </el-row>
        </div>
        <div style="padding-top: 10px">
          <div style="padding-top: 4px;padding-bottom: 4px;padding-left: 6px">
            {{$t("vibrationData.safetyHazardRecord")}}
          </div>
          <el-row :gutter="22">
            <el-col :span="3">&nbsp;</el-col>
            <el-col :span="9">
              <el-switch
                v-model="safetyHazardRecord"
                active-text="开"
                inactive-text="关">
              </el-switch>
            </el-col>
            <el-col :span="5">
              <el-button type="primary" style="width: 100%" @click="handleReadParam(51)">
                {{$t("vibrationData.read")}}
              </el-button>
            </el-col>
            <el-col :span="5">
              <el-button type="primary" style="width: 100%" @click="handleSetParam(51)">
                {{$t("vibrationData.set")}}
              </el-button>
            </el-col>
          </el-row>
        </div>
      </el-drawer>

      <el-row style="padding-top: 10px">
        <el-col :span="24">
          <el-button type="primary" style="width: 100%;height: 40px" @click="handleCalibration()">
            {{$t("vibrationData.calibration")}}
          </el-button>
        </el-col>
      </el-row>
      <el-row style="padding-top: 10px">
        <el-col :span="24">
          <el-button type="primary" style="width: 100%;height: 40px" @click="handleStartCollect()">
            {{$t("vibrationData.startCollect")}}
          </el-button>
        </el-col>
      </el-row>
      <el-row style="padding-top: 10px">
        <el-col :span="24">
          <el-select v-model="selectFrequencyValue" class="topselect" style="width: 100%;">
            <el-option
              v-for="item in frequencyList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row style="padding-top: 10px">
        <el-col :span="24">
          <el-button type="primary" style="width: 100%;height: 40px" @click="handleEndCollect()">
            {{$t("vibrationData.endCollect")}}
          </el-button>
        </el-col>
      </el-row>
      <el-row style="padding-top: 10px">
        <el-col :span="24">
          <el-button type="primary" style="width: 100%;height: 40px" @click="handleClearCollect()">
            {{$t("vibrationData.clearCollect")}}
          </el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
  import {collectFrequency, typeToArray} from "@/util/constant";
  import EocdMonitor from "@/views/elevator/EocdMonitor";

  const reconnectInterval = 3000; // 重试间隔时间,3秒

  export default {
    components: {EocdMonitor},
    data() {
      return {
        drawer: false,
        userRole: [],
        elevator: {
          dtuCode: "",
          nodeCode: "",
          floor: [],
        },
        FA: {
          currentState: "",
          currentCollectState: "",
          toTerminal: "",
          calibration: "",
          faultCode: "",
          collectTime: "",
          collectFrequency: "",
          collectTypeName: "",
          fileSize: "",
          uploadSize: "",
          upFloor: "",
          downFloor: "",
        },
        floorArr: [],
        loading: false,
        paramSet: false,
        createTime: "",
        dtuState: "info",
        connectState: "",
        selectFrequencyValue: "",
        setCycleValue: "",
        setDoorCycleValue: "",
        setFrequencyValue: "",
        setTotalSwitch: false,
        setCollectSwitch: false,
        setAutoUploadSwitch: false,
        setDoorCollectSwitch: false,
        setAlarmFileSwitch: false,
        safetyHazardSwitch: false,
        safetyHazardRecord: false,
        frequencys: [],
        frequencyList: typeToArray(collectFrequency),
      }
      ;
    },
    mounted() {
      let dtuCode = this.$route.query.dtuCode;
      let nodeCode = this.$route.query.nodeCode;
      let userId = this.$route.query.userId;
      if ((typeof userId) === "undefined") {
        this.paramSet = false;
      } else {
        //拿权限 userId 查看具体数值有没有
        this.$http.get("permissions/byUserId/" + userId).then(res => {
          this.userRole = res.data;
          this.userRole.forEach(item => {
            if (item.name === "电梯档案") {
              let authList = item.children;
              let isHaveAuth = authList.filter(auth => auth.index === 828);
              if (isHaveAuth.length === 0) {
                this.paramSet = false;
              } else {
                this.paramSet = true;
              }
            }
          });
        }).catch((error) => {
          this.paramSet = false;
        });
      }
      console.log(dtuCode + "_" + nodeCode);
      if ((typeof dtuCode) === "undefined") {
        this.$message.error("dtuCode 不能为空");
        return;
      }
      if ((typeof nodeCode) === "undefined") {
        this.$message.error("nodeCode 不能为空");
        return;
      }

      this.frequencys = collectFrequency;
      this.open(dtuCode, nodeCode);
    },
    methods: {
      open(dtuCode, nodeCode) {
        this.elevator.dtuCode = dtuCode;
        this.elevator.nodeCode = nodeCode;
        this.$nextTick(() => {
          this.$refs.eocdMonitor.load(dtuCode, nodeCode);
        });
      },
      handleDialogClose() {
        this.selectFrequencyValue = "";
        this.setCycleValue = "";
        this.setDoorCycleValue = "";
        this.setFrequencyValue = "";
        this.setTotalSwitch = false;
        this.setCollectSwitch = false;
        this.setAutoUploadSwitch = false;
        this.setDoorCollectSwitch = false;
        this.setAlarmFileSwitch = false;
      },
      handleStartCollect() {
        //'0': 250采样频率   '1'：500采样频率
        if (this.selectFrequencyValue === "") {
          this.$message.error("请选择手动采集评率");
        } else {
          this.$confirm("确定手动开始采集?", this.$t("common.tips"), {
            confirmButtonText: this.$t("common.confirm"),
            cancelButtonText: this.$t("common.cancel"),
            type: "warning",
            customClass: "messageBox",
          }).then(() => {
            let param = {
              dtuCode: this.elevator.dtuCode,
              nodeCode: this.elevator.nodeCode,
              collectFrequency: this.selectFrequencyValue,
            };
            this.$http.get("/eocdOperate/startCollect", param).then(res => {
              if (res.data.status == 0) {
                this.$message.error(res.data.err);
              } else {
                this.$message.success(res.data.data);
              }
            }).catch((error) => {
            });
          });
        }
      },
      handleEndCollect() {
        this.$confirm("确定手动停止采集?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
          customClass: "messageBox",
        }).then(() => {
          let param = {
            dtuCode: this.elevator.dtuCode,
            nodeCode: this.elevator.nodeCode,
          };
          this.$http.get("/eocdOperate/endCollect", param).then(res => {
            if (res.data.status == 0) {
              this.$message.error(res.data.err);
            } else {
              this.$message.success(res.data.data);
            }
          }).catch((error) => {
          });
        });
      },
      handleClearCollect() {
        this.$confirm("确定清空采集记录?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
          customClass: "messageBox",
        }).then(() => {
          this.loading2 = true;
          let param = {
            dtuCode: this.elevator.dtuCode,
            nodeCode: this.elevator.nodeCode,
            collectTime: null,
            collectFrequency: null,
            collectType: null,
          };
          this.$http.get("/eocdOperate/clearCollect", param).then(res => {
            if (res.data.status == 0) {
              this.$message.error(res.data.err);
              this.loading2 = false;
            } else {
              this.$message.success(res.data.data);
              this.handleSearchEOCDData();
            }
          }).catch((error) => {
          });
        });
      },
      handleParamsSetClose(done) {
        done();
      },
      handleSetParam(paramNo) {
        let paramValue = null;
        if (paramNo == 0) {
          paramValue = this.setTotalSwitch ? 1 : 0;
        }
        if (paramNo == 1) {
          paramValue = this.setCollectSwitch ? 1 : 0;
        }
        if (paramNo == 2) {
          if (this.setCycleValue === "") {
            this.$message.error("请填写采集周期");
            return;
          }
          paramValue = this.setCycleValue;
        }
        if (paramNo == 3) {
          if (this.setFrequencyValue === "") {
            this.$message.error("请选择采集频率");
            return;
          }
          paramValue = this.setFrequencyValue;
        }
        if (paramNo == 4) {
          paramValue = this.setAutoUploadSwitch ? 1 : 0;
        }
        if (paramNo == 5) {
          paramValue = this.setDoorCollectSwitch ? 1 : 0;
        }
        if (paramNo == 6) {
          if (this.setDoorCycleValue === "") {
            this.$message.error("开关门自动采集周期");
            return;
          }
          paramValue = this.setDoorCycleValue;
        }
        if (paramNo == 7) {
          paramValue = this.setAlarmFileSwitch ? 1 : 0;
        }
        if (paramNo === 50) {
          paramValue = this.safetyHazardSwitch ? 1 : 0;
        }
        if (paramNo === 51) {
          paramValue = this.safetyHazardRecord ? 1 : 0;
        }
        if (paramValue != null) {
          this.$confirm("确定设置参数?", this.$t("common.tips"), {
            confirmButtonText: this.$t("common.confirm"),
            cancelButtonText: this.$t("common.cancel"),
            type: "warning",
          }).then(() => {
            let param = {
              dtuCode: this.elevator.dtuCode,
              nodeCode: this.elevator.nodeCode,
              paramNo: paramNo,
              paramValue: paramValue,
            };
            this.$http.get("/eocdOperate/setParam", param).then(res => {
              if (res.data.status == 0) {
                this.$message.error(res.data.err);
              } else {
                this.$message.success(res.data.data);
              }
            }).catch((error) => {
            });
          });
        }
      },
      handleReadParam(paramNo) {
        let param = {
          dtuCode: this.elevator.dtuCode,
          nodeCode: this.elevator.nodeCode,
          paramNo: paramNo,
        };
        this.$http.get("/eocdOperate/readParam", param).then(res => {
          if (res.data.status == 0) {
            this.$message.error(res.data.err);
          } else {
            let data = res.data.data;
            if (data.paramNo === 0) {
              this.setTotalSwitch = data.paramValue == 1;
            }
            if (data.paramNo === 1) {
              this.setCollectSwitch = data.paramValue == 1;
            }
            if (data.paramNo === 2) {
              this.setCycleValue = data.paramValue + "";
            }
            if (data.paramNo === 3) {
              this.setFrequencyValue = this.frequencys[data.paramValue];
            }
            if (data.paramNo === 4) {
              this.setAutoUploadSwitch = data.paramValue == 1;
            }
            if (data.paramNo === 5) {
              this.setDoorCollectSwitch = data.paramValue == 1;
            }
            if (data.paramNo === 6) {
              this.setDoorCycleValue = data.paramValue + "";
            }
            if (data.paramNo === 7) {
              this.setAlarmFileSwitch = data.paramValue == 1;
            }
            if (data.paramNo === 50) {
              this.safetyHazardSwitch = data.paramValue == 1;
            }
            if (data.paramNo === 51) {
              this.safetyHazardRecord = data.paramValue == 1;
            }
            this.$message.success("参数读取成功！");
          }
        }).catch((error) => {
        });
      },
      handleCalibration() {
        this.$confirm("确定校准?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
          customClass: "messageBox",
        }).then(() => {
          let param = {
            dtuCode: this.elevator.dtuCode,
            nodeCode: this.elevator.nodeCode,
          };
          this.$http.get("/eocdOperate/adjust", param).then(res => {
            if (res.data.status == 0) {
              this.$message.error(res.data.err);
            } else {
              this.$message.success(res.data.data);
            }
          }).catch((error) => {
          });
        });
      },
    },
  };
</script>
<style>
.messageBox {
  width: 75%;
}
</style>
<style lang="scss" scoped>
.body {
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  position: relative;
}

.topselect {
  ::v-deep {
    .el-input__inner {
      height: 40px;
    }

    .el-input__prefix, .el-input__suffix {
      height: 40px;
    }

    /* 下面设置右侧按钮居中 */
    .el-input__suffix {
      top: 10px;
    }

    .el-input__icon {
      line-height: inherit;
    }

    .el-input__suffix-inner {
      display: inline-block;
    }
  }
}
</style>
